<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="nombre"
      :rules="formRules.nombre"
    >
      <v-text-field
        v-model="form.nombre"
        clearable
        flat
        label="Nombre"
        :error-messages="errors"
        :append-icon="form.nombre ? null : $vuetify.icons.values.equals"
        @click:append="clickCopyRazonSocialANombre"
      />
    </ValidationProvider>
    <v-text-field
      v-model="form.razon_social"
      clearable
      flat
      label="Razón social"
      :append-icon="form.razon_social ? null : $vuetify.icons.values.equals"
      @click:append="clickCopyNombreARazonSocial"
    />
    <div
      class="nif"
    >
      <v-text-field
        v-model="form.cif"
        clearable
        flat
        label="NIF"
        :messages="messagesNIF"
        @input="validateNIF"
      />
    </div>
    <b10-autocomplete
      v-model="form.idtcliente"
      :items="tclientes"
      item-value="idtcliente"
      item-text="descripcion"
      label="Tipo de cliente"
      clearable
    />
    <b10-autocomplete
      v-model="form.idtsector"
      :items="tsectores"
      item-value="idtsector"
      item-text="descripcion"
      label="Actividad"
      clearable
    />
    <b10-subheader
      label="Direcciones"
      :icon="$vuetify.icons.values.direcciones"
    />
    <b10-autocomplete
      v-model="form.cliente_direccion_postal"
      :items="direcciones"
      item-text="descripcion"
      label="Postal"
      return-object
      clearable
    />
    <b10-autocomplete-alert
      v-if="form.cliente_direccion_postal"
    >
      {{ form.cliente_direccion_postal.descripcion }}
    </b10-autocomplete-alert>
    <b10-autocomplete
      v-model="form.cliente_direccion_fiscal"
      :items="direcciones"
      item-text="descripcion"
      label="Fiscal"
      return-object
      clearable
    />
    <b10-autocomplete-alert
      v-if="form.cliente_direccion_fiscal"
    >
      {{ form.cliente_direccion_fiscal.descripcion }}
    </b10-autocomplete-alert>
    <b10-subheader
      label="Datos comerciales"
    />
    <b10-autocomplete
      v-model="form.idagente_comercial"
      :items="agentesComerciales"
      item-value="idagente_comercial"
      item-text="nombre"
      label="Agente"
      clearable
    />
    <b10-autocomplete
      v-model="form.idcomercial"
      :items="comerciales"
      item-value="idcomercial"
      item-text="nombre"
      label="Comercial"
      clearable
    />
    <b10-autocomplete
      v-model="form.idcanal_conocimiento"
      :items="canalesConocimiento"
      item-value="idcanal_conocimiento"
      item-text="descripcion"
      label="Canal de conocimiento"
      clearable
    />
    <b10-subheader
      label="Otros datos"
      :icon="$vuetify.icons.values.idioma"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="ididioma"
      :rules="formRules.ididioma"
    >
      <b10-autocomplete
        v-model="form.ididioma"
        :items="idiomas"
        item-value="ididioma"
        item-text="descripcion"
        label="Idioma"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-autocomplete
      v-model="form.idpais"
      :items="paises"
      item-value="idpais"
      item-text="descripcion"
      label="Nacionalidad"
      clearable
    />
    <b10-subheader
      label="Comentarios"
      :icon="$vuetify.icons.values.edit"
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ClienteEditFormData'
import { isValidNIF } from '@/utils/validate'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        nombre: null,
        razon_social: null,
        cif: null,
        idtcliente: null,
        idtsector: null,
        cliente_direccion_postal: null,
        cliente_direccion_fiscal: null,
        idagente_comercial: null,
        idcomercial: null,
        idcanal_conocimiento: null,
        ididioma: null,
        idpais: null,
        observaciones: null,
      },
      formRules: {
        nombre: { required: true },
        ididioma: { required: true },
      },
      tclientes: [],
      tsectores: [],
      agentesComerciales: [],
      comerciales: [],
      canalesConocimiento: [],
      idiomas: [],
      paises: [],
      direcciones: [],
      messagesNIF: null,
    }
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.tclientes = resp.data.selectTcliente.result.dataset
    this.tsectores = resp.data.selectTsector.result.dataset
    this.canalesConocimiento = resp.data.selectCanalConocimiento.result.dataset
    this.agentesComerciales = resp.data.selectAgenteComercial.result.dataset
    this.comerciales = resp.data.selectComercial.result.dataset
    this.idiomas = resp.data.selectIdioma.result.dataset
    this.paises = resp.data.selectPais.result.dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      const resp = await Data.selectLookupsFormData(this, formData.idcliente)
      this.direcciones = resp.data.selectClienteDireccion.result.dataset
      formData.cliente_direccion_postal = _.find(this.direcciones, { 'idcliente_direccion': formData.idclidir_postal })
      formData.cliente_direccion_fiscal = _.find(this.direcciones, { 'idcliente_direccion': formData.idclidir_fiscal })
      this.form = formData
    },
    validateNIF (value) {
      if (!value || isValidNIF(value)) {
        this.messagesNIF = null
      } else {
        this.messagesNIF = 'El formato del NIF no es válido'
      }
    },
    clickCopyRazonSocialANombre () {
      this.$set(this.form, 'nombre', this.form.razon_social)
    },
    clickCopyNombreARazonSocial () {
      this.$set(this.form, 'razon_social', this.form.nombre)
    },
  }
}
</script>

<style>
  .nif .v-messages__message  {
    color: var(--v-warning-base)
  }
</style>
